//Actions
export const GET_CLAIM = 'GET_CLAIM';
export const UPDATE_CLAIM = 'UPDATE_CLAIM';
export const ADD_CLAIMS = 'ADD_CLAIMS';
export const ADD_CLAIMS_REQUEST = 'ADD_CLAIMS_REQUEST';
export const RESET_CLAIMS = 'RESET_CLAIMS';
export const SET_CLAIMS_LOADING = 'SET_CLAIMS_LOADING';

//API
export const CLAIMS_API = '/fact-check/claims';
