export function languageCode() {
  const lang = [
    { language: 'All', code: 'all' },
    { language: 'Amharic', code: 'am' },
    { language: 'Arabic', code: 'ar' },
    { language: 'Basque', code: 'eu' },
    { language: 'Bengali', code: 'bn' },
    { language: 'English (UK)', code: 'en-GB' },
    { language: 'Portuguese (Brazil)', code: 'pt-BR' },
    { language: 'Bulgarian', code: 'bg' },
    { language: 'Catalan', code: 'ca' },
    { language: 'Cherokee', code: 'chr' },
    { language: 'Croatian', code: 'hr' },
    { language: 'Czech', code: 'cs' },
    { language: 'Danish', code: 'da' },
    { language: 'Dutch', code: 'nl' },
    { language: 'English (US)', code: 'en' },
    { language: 'Estonian', code: 'et' },
    { language: 'Filipino', code: 'fil' },
    { language: 'Finnish', code: 'fi' },
    { language: 'French', code: 'fr' },
    { language: 'German', code: 'de' },
    { language: 'Greek', code: 'el' },
    { language: 'Gujarati', code: 'gu' },
    { language: 'Hebrew', code: 'iw' },
    { language: 'Hindi', code: 'hi' },
    { language: 'Hungarian', code: 'hu' },
    { language: 'Icelandic', code: 'is' },
    { language: 'Indonesian', code: 'id' },
    { language: 'Italian', code: 'it' },
    { language: 'Japanese', code: 'ja' },
    { language: 'Kannada', code: 'kn' },
    { language: 'Korean', code: 'ko' },
    { language: 'Latvian', code: 'lv' },
    { language: 'Lithuanian', code: 'lt' },
    { language: 'Malay', code: 'ms' },
    { language: 'Malayalam', code: 'ml' },
    { language: 'Marathi', code: 'mr' },
    { language: 'Norwegian', code: 'no' },
    { language: 'Polish', code: 'pl' },
    { language: 'Portuguese (Portugal)', code: 'pt-PT' },
    { language: 'Romanian', code: 'ro' },
    { language: 'Russian', code: 'ru' },
    { language: 'Serbian', code: 'sr' },
    { language: 'Chinese (PRC)', code: 'zh-CN' },
    { language: 'Slovak', code: 'sk' },
    { language: 'Slovenian', code: 'sl' },
    { language: 'Spanish', code: 'es' },
    { language: 'Swahili', code: 'sw' },
    { language: 'Swedish', code: 'sv' },
    { language: 'Tamil', code: 'ta' },
    { language: 'Telugu', code: 'te' },
    { language: 'Thai', code: 'th' },
    { language: 'Chinese (Taiwan)', code: 'zh-TW' },
    { language: 'Turkish', code: 'tr' },
    { language: 'Urdu', code: 'ur' },
    { language: 'Ukrainian', code: 'uk' },
    { language: 'Vietnamese', code: 'vi' },
    { language: 'Welsh', code: 'cy' },
  ];
  return lang;
}
