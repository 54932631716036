//Actions
export const LOADING_SPACES = 'LOADING_SPACES';
export const SET_SELECTED_SPACE = 'SET_SELECTED_SPACE';
export const GET_SPACES_SUCCESS = 'GET_SPACES_SUCCESS';
export const GET_SPACES_FAILURE = 'GET_SPACES_FAILURE';
export const UPDATE_SPACE_SUCCESS = 'UPDATE_SPACE_SUCCESS';
export const UPDATE_SPACE_FAILURE = 'UPDATE_SPACE_FAILURE';
export const ADD_SPACE_SUCCESS = 'ADD_SPACE_SUCCESS';
export const ADD_SPACE_FAILURE = 'ADD_SPACE_SUCCESS';
export const DELETE_SPACE_SUCCESS = 'DELETE_SPACE_SUCCESS';
export const DELETE_SPACE_FAILURE = 'DELETE_SPACE_SUCCESS';

// tokens
export const SET_TOKENS_LOADING = 'SET_TOKENS_LOADING';
//API
export const API_SPACES = '/core/spaces';
