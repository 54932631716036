const initialState = {
  req: [],
  details: {},
  loading: true,
};

export default function organisationsReducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}
