//Actions
export const GET_CLAIMANT = 'GET_CLAIMANT';
export const UPDATE_CLAIMANT = 'UPDATE_CLAIMANT';
export const ADD_CLAIMANTS = 'ADD_CLAIMANTS';
export const ADD_CLAIMANTS_REQUEST = 'ADD_CLAIMANTS_REQUEST';
export const RESET_CLAIMANTS = 'RESET_CLAIMANTS';
export const SET_CLAIMANTS_LOADING = 'SET_CLAIMANTS_LOADING';

//API
export const CLAIMANTS_API = '/fact-check/claimants';
